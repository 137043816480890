var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"设计编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code',
                { rules: [{ required: true, message: '请输入' }] },
              ]),expression:"[\n                'code',\n                { rules: [{ required: true, message: '请输入' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                { rules: [{ required: true, message: '请输入' }] },
              ]),expression:"[\n                'name',\n                { rules: [{ required: true, message: '请输入' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同金额(万元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'contractAmount',
                     { rules: [{ required: true, message: '请输入' }] },
              ]),expression:"[\n                'contractAmount',\n                     { rules: [{ required: true, message: '请输入' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"责任部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'productionUnitId',
              ]),expression:"[\n                'productionUnitId',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"placeholder":""}},_vm._l((_vm.productionDepartmentList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.uniqueName)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"所属区域"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'areaId',
              ]),expression:"[\n                'areaId',\n              ]"}],attrs:{"placeholder":""}},_vm._l((_vm.areaList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.areaName)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("区域负责人")]),_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.selectedUser.name ? [_vm.selectedUser] : []},on:{"change":(arr) => {
                  if (arr.length) {
                    _vm.selectedUser = arr[0];
                  } else {
                    _vm.selectedUser = {};
                  }
                }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedUser.name)?_c('div',[_vm._v(_vm._s(_vm.selectedUser.name))]):_c('div',[_vm._v(" ")])])],1)],1)])],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("业主方")]),_c('CompanySelector',{on:{"change":(company) => (_vm.selectedCompany = company)}},[_c('a-button',{staticClass:"ellipsis",staticStyle:{"margin-top":"4px","text-align":"left"},attrs:{"block":""}},[_vm._v(_vm._s(_vm.selectedCompany.name))])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"归档情况"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'contractSignStatus',
              ]),expression:"[\n                'contractSignStatus',\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.signStatusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目地点"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'location',
              ]),expression:"[\n                'location',\n              ]"}],attrs:{"placeholder":"","options":_vm.cityList,"fieldNames":{
                label: 'name',
                value: 'name',
                children: 'children',
              }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"设计阶段"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phase',
              ]),expression:"[\n                'phase',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"mode":"multiple","placeholder":""}},_vm._l((_vm.phaseList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"专业"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'projectType',
              ]),expression:"[\n                'projectType',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"placeholder":""}},_vm._l((_vm.majorList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"承接时间"}},[_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'undertakingDate',
              ]),expression:"[\n                'undertakingDate',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"签订月份"}},[_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'signMonth',
              ]),expression:"[\n                'signMonth',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","saveLoading":_vm.saveLoading}},[_vm._v("提交")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }