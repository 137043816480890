<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="设计编号">
              <a-input
                v-decorator="[
                  'code',
                  { rules: [{ required: true, message: '请输入' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同金额(万元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                v-decorator="[
                  'contractAmount',
                       { rules: [{ required: true, message: '请输入' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="责任部门">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'productionUnitId',
                ]"
                style="width: 100%"
                placeholder
              >
                <a-select-option
                  v-for="item in productionDepartmentList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{
                  item.uniqueName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属区域">
              <a-select placeholder v-decorator="[
                  'areaId',
                ]">
                <a-select-option v-for="item in areaList" :key="item.id" :value="item.id">
                  {{
                  item.areaName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span slot="label" class>区域负责人</span>
              <div>
                <EmployeeSelector
                  @change="(arr) => {
                    if (arr.length) {
                      selectedUser = arr[0];
                    } else {
                      selectedUser = {};
                    }
                  }
                    "
                  :value="selectedUser.name ? [selectedUser] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="selectedUser.name">{{ selectedUser.name }}</div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span slot="label" class>业主方</span>
              <CompanySelector @change="(company) => (selectedCompany = company)">
                <a-button
                  block
                  style="margin-top: 4px; text-align: left"
                  class="ellipsis"
                >{{ selectedCompany.name }}</a-button>
              </CompanySelector>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="归档情况">
              <a-select
                style="width: 100%"
                v-decorator="[
                  'contractSignStatus',
                ]"
              >
                <a-select-option
                  v-for="item in signStatusList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="项目地点">
              <a-cascader
                placeholder
                v-decorator="[
                  'location',
                ]"
                :options="cityList"
                :fieldNames="{
                  label: 'name',
                  value: 'name',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="设计阶段">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                mode="multiple"
                v-decorator="[
                  'phase',
                ]"
                style="width: 100%"
                placeholder
              >
                <a-select-option v-for="item in phaseList" :key="item.value" :value="item.value">
                  {{
                  item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="专业">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'projectType',
                ]"
                style="width: 100%"
                placeholder
              >
                <a-select-option v-for="item in majorList" :key="item.value" :value="item.value">
                  {{
                  item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="承接时间">
              <a-month-picker
                placeholder
                v-decorator="[
                  'undertakingDate',
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="签订月份">
              <a-month-picker
                placeholder
                v-decorator="[
                  'signMonth',
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button type="primary" html-type="submit" :saveLoading="saveLoading">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import city from "@/mixins/city";
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";

import { mapGetters } from "vuex";

import EmployeeSelector from "@/components/employee-selector";
import CompanySelector from "@/components/company-selector";

import accurate from "accurate";

import request from "@/api/request";

function add(data) {
  return request({
    url: "/project-service/contractUndertaking/add",
    method: "post",
    data
  });
}

export default {
  mixins: [area, city, organization, watermark],

  components: {
    EmployeeSelector,
    CompanySelector
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedUser: {},
      selectedCompany: {},
      saveLoading: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    phaseList() {
      return this.findDataDict("phase");
    },
    signStatusList() {
      return this.findDataDict("contractSignStatus");
    },

    majorList() {
      return this.findDataDict("contract_scale");
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.save(values);
        }
      });
    },

    save(values) {
      this.saveLoading = true;

      let belongProvince, belongCity, district;
      if (values.location) {
        belongProvince = values.location[0];
        belongCity = values.location[1];
        district = values.location[2];
      }

      add({
        ...values,
        contractAmount: accurate.expr(`${values.contractAmount}*${10000}`),

        areaName: this.areaList.find(item => item.id === values.areaId)
          ?.areaName,

        areaMasterId: this.selectedUser.userId,
        areaMasterName: this.selectedUser.name,

        belongProvince,
        belongCity,
        district,

        partyAid: this.selectedCompany.id,
        partyAname: this.selectedCompany.name,

        phase: values.phase?.join(","),
        phaseName: values.phase
          ?.map(
            item => this.phaseList.find(element => element.value === item).name
          )
          .join(","),

        productionUnitName: this.productionDepartmentList.find(
          item => item.id === values.productionUnitId
        )?.uniqueName,

        projectTypeName: this.majorList.find(
          item => item.value === values.projectType
        )?.name,

        undertakingDate: values.undertakingDate?.format("YYYY-MM"),
        signMonth: values.signMonth?.format("YYYY-MM")
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding-top: 24px;
}

.center {
  padding: 80px 0;
}
</style>
